/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-stat-pane {
  border: 1px solid #d9d9d9;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.ticket-stat-pane .mychart {
  width: 100%;
  height: 100%;
}
.tablet .ticket-stat-pane,
.mobile .ticket-stat-pane {
  flex-direction: column;
  padding: 8px;
}
.tablet .ticket-stat-pane .mychart,
.mobile .ticket-stat-pane .mychart {
  margin-bottom: 16px;
}
.drone-lot-note {
  margin: 0 auto 24px;
  min-height: 250px;
  max-width: 500px;
}
